<template>
  <div class="home">
    <dialog id="dog"
            :open="isdog"
            status="no"
            style="z-index: 99;position: fixed;top:0;width: 100%;height: 100vh;background-color: #0e0e0ec0;border: none;">
      <div style="width: 1000px;background-color: aliceblue;padding: 15px;position: absolute;top: 50%;left: 50%;transform: translate(-50%,-50%);">
        <div style="width: 100%;height: 50px;background-color: aliceblue;margin-bottom: 5px;display: flex;align-items: center;justify-content: space-between;">
          <span style="color: #0365f8;">视频播放</span>
          <span 
              @click="godog"
              style="width: 30px;height: 30px;background-color: #ff0000;border-radius: 50%;cursor: pointer;text-align: center;line-height: 30px;color: aliceblue;"
          >
              X
          </span>
        </div>
        <video 
            id="vid"
            controls
            style="width: 100%;"
            src="../assets/sp001.mp4">
        </video>
      </div>

    </dialog>
    


    <el-carousel 
        height="800px"
        indicator-position="none"
    >
      <el-carousel-item style="text-align: center;">
        <div style="width: 100%;height: 100%;background-image: url('../assets/goin2.png');">
          <img 
              src="../assets/海报--第3届校园观测比赛.png"
              @click="goActivity"
              style="height: 100%;"
              alt=""
          >
        </div>
      </el-carousel-item>
      <el-carousel-item class="head">
        <img 
            class="bg"
            @click="gowq"
            src="../assets/image/home-bg.png"
            alt=""
        />
        <p class="title" @click="gowq">
          <img src="../assets/image/icon-sunny.png"
               alt=""
               class="icon-weather" />玩转科学，乐享其中
        </p>
        <p @click="gowq">科学玩家</p>
        <p @click="gowq">
          首创校园气象STEM课程体系，<br />
          依据《义务教育小学科学课程标准》、<br />
          《中小学综合实践活动课程指导纲要》、<br />
          融合STEM、PBL设计理念，符合国家教育双减政策
        </p>
      </el-carousel-item>
      <el-carousel-item style="text-align: center;">
        <div style="width: 100%;height: 100%;background-image: url('../assets/goin2.png');">
          <img src="../assets/goin1.jpg"
               @click="goGis"
               style="height: 100%;"
               alt="">
        </div>
      </el-carousel-item>
      <el-carousel-item style="text-align: center;">
        <img src="../assets/goin2.png"
             @click="godog"
             style="height: 100%;"
             alt="">
      </el-carousel-item>
    </el-carousel>
    <div style="text-align: center; margin: 20px 0;"  @click="goActivity">
      <img src="../assets/横栏（点击后弹出报名+观测页面）.png" alt="" style="width: 100%;"/>
    </div>
    <div class="content_box">
      <div class="content">
        <img src="../assets/image/icon-sunny.png"
             alt=""
             class="icon-sunny" />
        <img src="../assets/image/icon-rainy.png"
             alt=""
             class="icon-rainy" />
        <div class="item"
             @click="goGis">
          <div class="wrapper">
            <img src="../assets/image/icon-lesson-activity.png"
                 alt=""
                 class="icon" />
            <p class="name">科学观测网</p>
            <p class="text">查看年度科学探索活动和赛事</p>
          </div>
        </div>
        <div class="item"
             @click="goht">
          <div class="wrapper">
            <img src="../assets/image/icon-lesson-teacher.png"
                 alt=""
                 class="icon" />
            <p class="name">智能气象站</p>
            <p class="text">多要素、云服务、物联网气象站。</p>
          </div>
        </div>
        <div class="item"
             @click="gowe">
          <div class="wrapper">
            <img src="../assets/image/icon-lesson-school.png"
                 alt=""
                 class="icon" />
            <p class="name">科学联盟</p>
            <p class="text">成为科学公民，加入科学联盟</p>
          </div>
        </div>
        <div class="item"
             @click="gozx">
          <div class="wrapper">
            <img src="../assets/image/icon-lesson-meteor.png"
                 alt=""
                 class="icon" />
            <p class="name">气象课</p>
            <p class="text">玩转科学，乐享其中</p>
          </div>
        </div>
      </div>
    </div>
    <div class="middle"
         @click="goGis">
      <div class="wrapper">
        <img src="../assets/image/lianmeng.png"
             alt="" />
        <div>
          <p>中国科普学习和观测联盟</p>
          <p>CASO（Chinese Alliance of Science and Observation）</p>
          <p>
            面向社会大众的、全年龄段的，集科普学习、科学观测、科学探究、知识共享和数据应用为一体的平台
          </p>
        </div>
        <span>查看详情</span>
      </div>
    </div>

    <div style="text-align: center;" @click="goStudy">
      <img src="../assets/231714012563_.pic.jpg" alt="" style="width: 100%;"/>
    </div>

  </div>
</template>

<script>
import { ROOT } from "@/services/base";
import { $lesson } from "@/services";
export default {
  name: "Home",
  data () {
    return {
      isdog: false,
      menuList: [],
    };
  },
  created () {
    $lesson.getMenuList().then((res) => {
      this.menuList = res.data.map((menu) => {
        return {
          ...menu,
          _icon: `${ROOT}/${menu.icon}`,
        };
      });
    });
  },
  methods: {
    godog () {
      let m = document.getElementById('vid')
      if (this.isdog) {
        m.pause()
      } else if (!this.isdog) {
        m.play()
      }
      this.isdog = !this.isdog
    },
    goStudy (id) {
      this.$router.push({
        name: "onlineStudy",
        params: {
          group: id,
        },
      });
    },
    goGis () {
      // this.$router.push("/map");
      // this.props.history.push('/map')

      const router = this.$router.resolve({ path: '/map' })
      window.open(router.href, '_blank')
    },
    goActivity() {
        const router = this.$router.resolve({ path: '/activity' })
        window.open(router.href, '_blank')
    },
    goht () {
      window.open('https://mp.weixin.qq.com/s/SnoslZcynajhAuH77ITCgQ', '_blank')
      // window.location = "https://mp.weixin.qq.com/s/cdgJdqIFNJItnqu5zCnuww"
    },
    gowe () {
      window.open('https://mp.weixin.qq.com/s/JLks38pjlw38pgJGeEv31A', '_blank')
      // window.location = "https://mp.weixin.qq.com/s/JLks38pjlw38pgJGeEv31A"
    },
    gowq () {
      // window.open('https://mp.weixin.qq.com/s/nqe7cg2DuLugY4ekTLy38Q', '_blank')
      window.open('https://mp.weixin.qq.com/s/bCB10HyxsJroOr56K5JKvg', '_blank')
      // window.location = "https://mp.weixin.qq.com/s/nqe7cg2DuLugY4ekTLy38Q"
    },
    gozx () {
      this.$router.push("/qixiang")
    }
  },
};
</script>

<style lang="less">
.home {
  position: relative;

  .head {
    background: #fff45c;
    padding: 200px 300px;
    .bg {
      width: 63%;
      position: absolute;
      z-index: 3;
      right: 0;
      top: 0;
      user-select: none;
    }
    p {
      margin: 0;
      &:first-of-type {
        font-size: 62px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #191c1f;
        line-height: 98px;
        position: relative;
        .icon-weather {
          position: absolute;
          left: -87px;
          top: -144px;
        }
      }

      &:nth-of-type(2) {
        font-size: 62px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #e1254f;
        line-height: 98px;
      }

      &:last-child {
        font-size: 26px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #7078ac;
        line-height: 51px;
      }
    }
  }

  > .content_box {
    position: relative;
    width: 100%;
    height: 794px;
    background: linear-gradient(
      0deg,
      fade(#0b01b8, 27) 10%,
      fade(#fdfa03, 27) 50%,
      fade(#ffffff, 27) 90%
    );
    background-color: white;
    padding: 50px 60px;
    z-index: 4;
    > .content {
      width: 1248px;
      margin: 86px auto 0;
      position: relative;
      z-index: 3;
      display: flex;
      justify-content: space-between;
      .icon-sunny {
        position: absolute;
        left: -69px;
        top: -52px;
        z-index: 1;
      }
      .icon-rainy {
        position: absolute;
        bottom: -64px;
        right: -91px;
        z-index: 1;
      }
      > .item {
        cursor: pointer;
        position: relative;
        z-index: 2;
        width: 300px;
        height: 510px;
        background: #ffffff;
        border: 1px solid fade(#525252, 50);
        border-radius: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        .wrapper {
          box-sizing: border-box;
          padding: 0 45px;
          width: 284px;
          height: 494px;
          background: #ffffff;
          border: 1px solid fade(#525252, 50);
          border-radius: 15px;
          display: flex;
          flex-direction: column;
          align-items: center;
          .icon {
            margin-top: 32px;
          }
          .name {
            margin-top: 32px;
            line-height: 1;
            font-size: 30px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #484848;
          }
          .text {
            text-align: center;
            margin-top: 14px;
            font-size: 18px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #484848;
            line-height: 32px;
          }
        }
      }
    }
  }
  > .middle {
    cursor: pointer;
    width: 100%;
    height: 341px;
    background-image: url('../assets/image/bg-home1.png');
    .wrapper {
      position: relative;
      width: 1280px;
      margin: 0 auto;
      padding: 90px 0 72px;
      > img {
        width: 151px;
        vertical-align: middle;
      }
      > div {
        display: inline-block;
        vertical-align: middle;
        margin-left: 20px;
        > p {
          &:nth-child(1) {
            letter-spacing: 14px;
            font-size: 44px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #ffffff;
            line-height: 50px;
          }
          &:nth-child(2) {
            letter-spacing: 1.7px;
            text-transform: uppercase;
            font-size: 18px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #ffffff;
            line-height: 53px;
          }
          &:nth-child(3) {
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #ffffff;
            line-height: 50px;
          }
        }
      }
      > span {
        position: absolute;
        right: 51px;
        bottom: 13px;
        width: 215px;
        line-height: 38px;
        background: #ff8aad;
        border-radius: 10px;
        font-size: 20px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
        text-align: center;
        cursor: pointer;
      }
    }
  }
  .flex-center {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .kecheng {
    margin-top: 39px;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    align-items: flex-start;
    justify-content: flex-start;
    .item {
      cursor: pointer;
      margin-top: 39px;
      width: 366px;
      margin-left: 90px;
      &:nth-child(3n + 1) {
        margin-left: 0;
      }
      img {
        width: 100%;
      }
      h3 {
        font-size: 36px;
        color: #000;
        font-weight: bold;
        text-align: center;
      }
    }
  }
  .learn {
    width: 1280px;
    padding: 125px 0;
    margin: 0 auto;
    position: relative;
    .bg {
      position: absolute;
      width: 259px;
      left: 0;
      top: 102px;
    }
    .title {
      width: 100%;
      text-align: center;
      font-size: 28px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #000000;
    }
    .intro {
      margin-top: 24px;
      width: 100%;
      text-align: center;
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #000000;
      line-height: 26px;
    }
  }
}

@media screen and (max-width: 1366px) {
  .home > .head {
    padding: 200px 70px;
  }
}
</style>